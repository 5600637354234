import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Footer from "../components/sections/footer"

import styled from "styled-components"
import { Container } from "../components/global"

const DataProtectionPage = () => (
  <Layout>
    <SEO title="apocha" />
    <Navigation />
    <DataProtectionContainer>
    <h2>Datenschutzerklärung</h2>
    <h4>Allgemeine Hinweise</h4>
    <p>Wir informieren Sie nachfolgend gemäß den gesetzlichen Vorgaben des Datenschutzrechts (insb. gemäß BDSG n.F.
        und der europäischen Datenschutz-Grundverordnung ‚DS-GVO‘) über die Art, den Umfang und Zweck der Verarbeitung
        personenbezogener Daten durch unser Unternehmen. Diese Datenschutzerklärung gilt auch für unsere Websites,
        Webapplikationen und Sozial-Media-Profile. Bezüglich der Definition von Begriffen wie etwa „personenbezogene
        Daten“ oder „Verarbeitung“ verweisen wir auf Art. 4 DS-GVO.</p>

    <h5>Name und Kontaktdaten des / der Verantwortlichen</h5>
    <p>Unser/e Verantwortliche/r (nachfolgend „Verantwortlicher“) i.S.d. Art. 4 Zif. 7 DS-GVO ist:</p>
    <p>
        Olaf Kortlüke<br /> Im Brachmoos 12<br /> 88149 Nonnenhorn<br /> Deutschland<br /> E-Mail-Adresse: olaf@apocha.biz
    </p>

    <h5>Datenarten, Zwecke der Verarbeitung und Kategorien betroffener Personen</h5>
    <p>Nachfolgend informieren wir Sie über Art, Umfang und Zweck der Erhebung, Verarbeitung und Nutzung
        personenbezogener Daten.</p>

    <h5>1. Arten der Daten, die wir verarbeiten</h5>
    <p>Nutzungsdaten (Zugriffszeiten, besuchte Websites etc.), Bestandsdaten (Name, Adresse etc.), Kontaktdaten
        (Telefonnummer, E-Mail, Fax etc.), Zahlungsdaten (Bankdaten, Kontodaten, Zahlungshistorie etc.), Vertragsdaten
        (Gegenstand des Vertrages, Laufzeit etc.), Inhaltsdaten (Texteingaben, Videos, Fotos etc.), Kommunikationsdaten
        (IP-Adresse etc.),</p>

    <h5>2. Zwecke der Verarbeitung nach Art. 13 Abs. 1 c) DS-GVO</h5>
    <p>Abwicklung von Verträgen, Website technisch und wirtschaftlich optimieren, leichten Zugang zur Website
        ermöglichen, Erfüllung vertraglicher Verpflichtungen, Optimierung und statistische Auswertung unserer Dienste,
        kommerzielle Nutzung der Website unterstützen, Nutzererfahrung verbessern, Website benutzerfreundlich gestalten,
        wirtschaftlicher Betrieb der Werbung und Website, Marketing / Vertrieb / Werbung, Erstellung von Statistiken,
        Kontaktanfragen abwickeln, Websites mit Funktionen und Inhalten bereitstellen, Unterbrechungsfreier, sicherer
        Betrieb unserer Website,</p>

    <h5>3. Kategorien der betroffenen Personen nach Art. 13 Abs. 1 e) DS-GVO</h5>
    <p>Besucher/Nutzer der Website, der Webapplikation und Kunden. Die betroffenen Personen werden zusammenfassend
        als „Nutzer“ bezeichnet.</p>

    <h5>Rechtsgrundlagen der Verarbeitung personenbezogener Daten</h5>
    <p>Nachfolgend Informieren wir Sie über die Rechtsgrundlagen der Verarbeitung personenbezogener Daten:</p>
    <ol>
        <li>Wenn wir Ihre Einwilligung für die Verarbeitung personenbezogenen Daten eingeholt haben, ist Art. 6
            Abs. 1 S. 1 lit. a) DS-GVO Rechtsgrundlage.</li>
        <li>Ist die Verarbeitung zur Erfüllung eines Vertrags oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich, die auf Ihre Anfrage hin erfolgen, so ist Art. 6 Abs. 1 S. 1 lit. b) DS-GVO Rechtsgrundlage.</li>
        <li>Ist die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der wir unterliegen
            (z.B. gesetzliche Aufbewahrungspflichten), so ist Art. 6 Abs. 1 S. 1 lit. c) DS-GVO Rechtsgrundlage.</li>
        <li>Ist die Verarbeitung erforderlich, um lebenswichtige Interessen der betroffenen Person oder einer
            anderen natürlichen Person zu schützen, so ist Art. 6 Abs. 1 S. 1 lit. d) DS-GVO Rechtsgrundlage.</li>
        <li>Ist die Verarbeitung zur Wahrung unserer oder der berechtigten Interessen eines Dritten erforderlich
            und überwiegen diesbezüglich Ihre Interessen oder Grundrechte und Grundfreiheiten nicht, so ist Art. 6 Abs.
            1 S. 1 lit. f) DS-GVO Rechtsgrundlage.</li>
    </ol>

    <h5>Weitergabe personenbezogener Daten an Dritte und Auftragsverarbeiter</h5>
    <p>Ohne Ihre Einwilligung geben wir grundsätzlich keine Daten an Dritte weiter. Sollte dies doch der Fall sein,
        dann erfolgt die Weitergabe auf der Grundlage der zuvor genannten Rechtsgrundlagen z.B. bei der Weitergabe von
        Daten an Online-Paymentanbieter zur Vertragserfüllung oder aufgrund gerichtlicher Anordnung oder wegen einer
        gesetzlichen Verpflichtung zur Herausgabe der Daten zum Zwecke der Strafverfolgung, zur Gefahrenabwehr oder zur
        Durchsetzung der Rechte am geistigen Eigentum. Wir setzen zudem Auftragsverarbeiter (externe Dienstleister z.B.
        zum Webhosting unserer Websites und Datenbanken) zur Verarbeitung Ihrer Daten ein. Wenn im Rahmen einer
        Vereinbarung zur Auftragsverarbeitung an die Auftragsverarbeiter Daten weitergegeben werden, erfolgt dies immer
        nach Art. 28 DS-GVO. Wir wählen dabei unsere Auftragsverarbeiter sorgfältig aus und haben uns ein Weisungsrecht
        hinsichtlich der Daten einräumen lassen. Zudem müssen die Auftragsverarbeiter geeignete technische und
        organisatorische Maßnahmen getroffen haben und die Datenschutzvorschriften gem. BDSG n.F. und DS-GVO einhalten</p>

    <h5>Datenübermittlung in Drittstaaten</h5>
    <p>Durch die Verabschiedung der europäischen Datenschutz-Grundverordnung (DS-GVO) wurde eine einheitliche
        Grundlage für den Datenschutz in Europa geschaffen. Ihre Daten werden daher vorwiegend durch Unternehmen
        verarbeitet, für die DS-GVO Anwendung findet. Sollte doch die Verarbeitung durch Dienste Dritter außerhalb der
        Europäischen Union oder des Europäischen Wirtschaftsraums stattfinden, so müssen diese die besonderen
        Voraussetzungen der Art. 44 ff. DS-GVO erfüllen. Das bedeutet, die Verarbeitung erfolgt aufgrund besonderer
        Garantien, wie etwa die von der EU-Kommission offiziell anerkannte Feststellung eines der EU entsprechenden
        Datenschutzniveaus oder der Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen, der so
        genannten „Standardvertragsklauseln“. Bei US-Unternehmen erfüllt die Unterwerfung unter das sog.
        „Privacy-Shield“, dem Datenschutzabkommen zwischen der EU und den USA, diese Voraussetzungen.</p>

    <h5>Löschung von Daten und Speicherdauer</h5>
    <p>Sofern nicht in dieser Datenschutzerklärung ausdrücklich angegeben, werden Ihre personenbezogen Daten
        gelöscht oder gesperrt, sobald der Zweck für die Speicherung entfällt, es sei denn deren weitere Aufbewahrung
        ist zu Beweiszwecken erforderlich oder dem stehen gesetzliche Aufbewahrungspflichten entgegenstehen. Darunter
        fallen etwa handelsrechtliche Aufbewahrungspflichten von Geschäftsbriefen nach § 257 Abs. 1 HGB (6 Jahre) sowie
        steuerrechtliche Aufbewahrungspflichten nach § 147 Abs. 1 AO von Belegen (10 Jahre). Wenn die vorgeschriebene
        Aufbewahrungsfrist abläuft, erfolgt eine Sperrung oder Löschung Ihrer Daten, es sei denn die Speicherung ist
        weiterhin für einen Vertragsabschluss oder zur Vertragserfüllung erforderlich.</p>

    <h5>Bestehen einer automatisierten Entscheidungsfindung</h5>
    <p>Wir setzen keine automatische Entscheidungsfindung oder ein Profiling ein.</p>

    <h4>Datenerhebung bei der Bereitstellung unserer Website</h4>
    <h5>Server-Logfiles</h5>
    <ol>
        <li>Wenn Sie unsere Webseite lediglich informatorisch nutzen (keine Registrierung und auch keine
            anderweitige Übermittlung von Informationen), erheben wir nur die personenbezogenen Daten, die Ihr Browser
            an unseren Server übermittelt. Wenn Sie unsere Website betrachten, erheben wir die folgenden Daten:
            IP-Adresse, Datum und Uhrzeit des Abrufs, Browsertyp, Sprache und Browser-Version, Betriebssystem, Inhalt
            des Abrufs, Zeitzone, Zugriffsstatus/HTTP-Statuscode, Datenmenge, sowie die Website, von der die Anforderung
            kommt (Referer-URL). Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten von Ihnen
            findet nicht statt.</li>
        <li>Diese Daten dienen dem Zweck der nutzerfreundlichen, funktionsfähigen und sicheren Auslieferung unserer
            Website an Sie mit Funktionen und Inhalten sowie deren Optimierung und statistischen Auswertung.</li>
        <li>Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes berechtigtes Interesse an der
            Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.</li>
        <li>Wir speichern aus Sicherheitsgründen diese Daten in Server-Logfiles für die Speicherdauer von 14 Tagen.
            Nach Ablauf dieser Frist werden diese automatisch gelöscht, es sei denn wir benötigen deren Aufbewahrung zu
            Beweiszwecken bei Angriffen auf die Serverinfrastruktur oder anderen Rechtsverletzungen.</li>
    </ol>

    <h5>Cookies</h5>
    <ol>
        <li>Wir verwenden sog. Cookies bei Ihrem Besuch unserer Website. Cookies sind kleine Textdateien, die Ihr
            Internet-Browser auf Ihrem Rechner ablegt und speichert. Wenn Sie unsere Website erneut aufrufen, geben
            diese Cookies Informationen ab, um Sie automatisch wiederzuerkennen. Die so erlangten Informationen dienen
            dem Zweck, unsere Webangebote technisch und wirtschaftlich zu optimieren und Ihnen einen leichteren und
            sicheren Zugang auf unsere Website zu ermöglichen. Wir informieren Sie dazu beim Aufruf unserer Website
            mittels eines Hinweises auf unsere Datenschutzerklärung über die Verwendung von Cookies zu den zuvor
            genannten Zwecken und wie Sie dieser widersprechen bzw. deren Speicherung verhindern können („Opt-out“).
            Unsere Website nutzt Session-Cookies, persistente Cookies und Cookies von Drittanbietern:
            <dl>
                <dt>Session-Cookies:</dt>
                <dd>Wir verwenden sog. Cookies zum Wiedererkennen mehrfacher Nutzung eines Angebots durch denselben
                    Nutzer (z.B. wenn Sie sich eingeloggt haben zur Feststellung Ihres Login-Status). Wenn Sie unsere
                    Seite erneut aufrufen, geben diese Cookies Informationen ab, um Sie automatisch wiederzuerkennen.
                    Die so erlangten Informationen dienen dazu, unsere Angebote zu optimieren und Ihnen einen leichteren
                    Zugang auf unsere Seite zu ermöglichen. Wenn Sie sich ausloggen, werden die Session-Cookies
                    gelöscht.</dd>
                <dt>Persistente Cookies:</dt>
                <dd>Diese werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie
                    unterscheiden kann. In den Sicherheitseinstellungen Ihres Browsers können Sie die Cookies jederzeit
                    löschen.</dd>
                <dt>Cookies von Drittanbietern (Third-Party-Cookies):</dt>
                <dd>Entsprechend Ihren Wünschen können Sie Ihre Browser-Einstellung konfigurieren und z.B. die
                    Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie jedoch an dieser Stelle
                    darauf hin, dass Sie dann eventuell nicht alle Funktionen dieser Website nutzen können. Lesen Sie
                    Näheres zu diesen Cookies bei den jeweiligen Datenschutzerklärungen zu den Drittanbietern.</dd>
            </dl>
        </li>
        <li>Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 S. lit. b) DS-GVO, wenn die Cookies zur
            Vertragsanbahnung z.B. bei Bestellungen gesetzt werden und ansonsten haben wir ein berechtigtes Interesse an
            der effektiven Funktionalität der Website, so dass in dem Falle Art. 6 Abs. 1 S. 1 lit. f) DS-GVO
            Rechtsgrundlage ist.</li>
        <li>Das Speichern von Cookies auf Ihrer Festplatte können Sie allgemein verhindern, indem Sie in Ihren
            Browser-Einstellungen „keine Cookies akzeptieren“ wählen. Dies kann aber eine Funktionseinschränkung unserer
            Angebote zur Folge haben. Sie können dem Einsatz von Cookies von Drittanbietern zu Werbezwecken über ein
            sog. „Opt-out“ über diese amerikanische Website (<a href="https://optout.aboutads.info" target="_blank"
            rel="nofollow noopener noreferrer">https://optout.aboutads.info</a>) oder diese europäische Website (<a
            href="http://www.youronlinechoices.com/de/praferenzmanagement/" target="_blank" rel="nofollow noopener noreferrer">http://www.youronlinechoices.com/de/praferenzmanagement/</a>)
            widersprechen.
        </li>
    </ol>

    <h5>Abwicklung von Verträgen</h5>
    <ol>
        <li>Wir verarbeiten Bestandsdaten (z.B. Unternehmen, Titel/akademischer Grad, Namen und Adressen sowie
            Kontaktdaten von Nutzern, E-Mail), Vertragsdaten (z.B. in Anspruch genommene Leistungen, Namen von
            Kontaktpersonen) und Zahlungsdaten (z.B. Bankverbindung, Zahlungshistorie) zwecks Erfüllung unserer
            vertraglichen Verpflichtungen (Kenntnis, wer Vertragspartner ist; Begründung, inhaltliche Ausgestaltung und
            Abwicklung des Vertrags; Überprüfung auf Plausibilität der Daten) und Serviceleistungen (z.B.
            Kontaktaufnahme des Kundenservice) gem. Art. 6 Abs. 1 S. 1 lit b) DS-GVO. Die in Onlineformularen als
            verpflichtend gekennzeichneten Eingaben, sind für den Vertragsschluss erforderlich.</li>
        <li>Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, außer sie ist zur Verfolgung
            unserer Ansprüche (z.B. Übergabe an Rechtsanwalt zum Inkasso) oder zur Erfüllung des Vertrags (z.B. Übergabe
            der Daten an Zahlungsanbieter) erforderlich oder es besteht hierzu besteht eine gesetzliche Verpflichtung
            gem. Art. 6 Abs. 1 S. 1 lit. c) DS-GVO.</li>
        <li>Wir können die von Ihnen angegebenen Daten zudem verarbeiten, um Sie über weitere interessante Produkte
            aus unserem Portfolio zu informieren oder Ihnen E-Mails mit technischen Informationen zukommen lassen.</li>
        <li>Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr
            erforderlich sind. Dies ist für die Bestands- und Vertragsdaten dann der Fall, wenn die Daten für die
            Durchführung des Vertrages nicht mehr erforderlich sind und keine Ansprüche mehr aus dem Vertrag geltend
            gemacht werden können, weil diese verjährt sind (Gewährleistung: zwei Jahre / Regelverjährung: drei Jahre).
            Wir sind aufgrund handels- und steuerrechtlicher Vorgaben verpflichtet, Ihre Adress-, Zahlungs- und
            Bestelldaten für die Dauer von zehn Jahren zu speichern. Allerdings nehmen wir bei Vertragsbeendigung nach
            drei Jahren eine Einschränkung der Verarbeitung vor, d. h. Ihre Daten werden nur zur Einhaltung der
            gesetzlichen Verpflichtungen eingesetzt. Angaben im Nutzerkonto verbleiben bis zu dessen Löschung.</li>
    </ol>

    <h5>Kontaktaufnahme per Kontaktformular / E-Mail / Fax / Post</h5>
    <ol>
        <li>Bei der Kontaktaufnahme mit uns per Kontaktformular, Fax, Post oder E-Mail werden Ihre Angaben zum
            Zwecke der Abwicklung der Kontaktanfrage verarbeitet.</li>
        <li>Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung von Ihnen Art. 6
            Abs. 1 S. 1 lit. a) DS-GVO. Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer Kontaktanfrage
            oder E-Mail, eines Briefes oder Faxes übermittelt werden, ist Art. 6 Abs. 1 S. 1 lit. f) DS-GVO. Der
            Verantwortliche hat ein berechtigtes Interesse an der Verarbeitung und Speicherung der Daten, um Anfragen
            der Nutzer beantworten zu können, zur Beweissicherung aus Haftungsgründen und um ggf. seiner gesetzlichen
            Aufbewahrungspflichten bei Geschäftsbriefen nachkommen zu können. Zielt der Kontakt auf den Abschluss eines
            Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 S. 1 lit. b) DS-GVO.</li>
        <li>Wir können Ihre Angaben und Kontaktanfrage in unserem Customer-Relationship-Management System ("CRM
            System") oder einem vergleichbaren System speichern.</li>
        <li>Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr
            erforderlich sind. Für die personenbezogenen Daten aus der Eingabemaske des Kontaktformulars und diejenigen,
            die per E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation mit Ihnen beendet
            ist. Beendet ist die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene
            Sachverhalt abschließend geklärt ist. Anfragen von Nutzern, die über einen Account bzw. Vertrag mit uns
            verfügen, speichern wir bis zum Ablauf von zwei Jahren nach Vertragsbeendigung. Im Fall von gesetzlichen
            Archivierungspflichten erfolgt die Löschung nach deren Ablauf: Ende handelsrechtlicher (6 Jahre) und
            steuerrechtlicher (10 Jahre) Aufbewahrungspflicht.</li>
        <li>Sie haben jederzeit die Möglichkeit, die Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a) DS-GVO zur
            Verarbeitung der personenbezogenen Daten zu widerrufen. Nehmen Sie per E-Mail Kontakt mit uns auf, so können
            Sie der Speicherung der personenbezogenen Daten jederzeit widersprechen.</li>
    </ol>

    <h5>Präsenz in sozialen Medien</h5>
    <ol>
        <li>Wir unterhalten in sozialen Medien Profile bzw. Fanpages, um mit den dort angeschlossenen und
            registrierten Nutzern zu kommunizieren und um über unsere Produkte, Angebote und Dienstleistungen zu
            informieren. Die US-Anbieter sind nach dem sog. Privacy-Shield zertifiziert und damit verpflichtet
            europäischen Datenschutz einzuhalten. Bei der Nutzung und dem Aufruf unseres Profils im jeweiligen Netzwerk
            durch Sie gelten die jeweiligen Datenschutzhinweise und Nutzungsbedingungen des jeweiligen Netzwerks.</li>
        <li>Wir verarbeiten Ihre Daten, die Sie uns über diese Netzwerke senden, um mit Ihnen zu kommunizieren und
            um Ihre dortigen Nachrichten zu beantworten.</li>
        <li>Die Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten ist unser berechtigtes Interesse
            an der Kommunikation mit den Nutzern und unsere Außendarstellung zwecks Werbung gemäß Art. 6 Abs. 1 S. 1
            lit. f) DS-GVO. Soweit Sie dem Verantwortlichen des sozialen Netzwerks eine Einwilligung in die Verarbeitung
            Ihrer personenbezogenen Daten erteilt haben, ist Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. a) und Art. 7
            DS-GVO.</li>
        <li>Die Datenschutzhinweise, Auskunftsmöglichkeiten und Widerspruchmöglichkeiten (Opt-Out) der jeweiligen
            Netzwerke finden Sie hier:
            <dl>
                <dt>Facebook</dt>
                <dd>
                    (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland)<br />
                    Datenschutzerklärung: <a href="https://www.facebook.com/about/privacy/" target="_blank"
                        rel="nofollow noopener noreferrer">https://www.facebook.com/about/privacy/</a><br /> Opt-Out: <a
                        href="https://www.facebook.com/settings?tab=ads" target="_blank" rel="nofollow noopener noreferrer">https://www.facebook.com/settings?tab=ads</a>
                    und <a href="http://www.youronlinechoices.com" target="_blank" rel="nofollow noopener noreferrer">http://www.youronlinechoices.com</a><br />
                    Privacy Shield: <a
                        href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active"
                        target="_blank" rel="nofollow noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active</a>.<br />
                    <br />
                </dd>
                <dt>Twitter</dt>
                <dd>
                    (Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA)<br />
                    Datenschutzerklärung: <a href="https://twitter.com/de/privacy" target="_blank"
                        rel="nofollow noopener noreferrer">https://twitter.com/de/privacy</a><br /> Opt-Out: <a
                        href="https://twitter.com/personalization" target="_blank" rel="nofollow noopener noreferrer">https://twitter.com/personalization</a><br />
                    Privacy Shield: <a
                        href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active"
                        target="_blank" rel="nofollow noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active</a>.
                </dd>
            </dl>
        </li>
    </ol>

    <h4>Analyse Tools und Werbung</h4>

    <h5>Google Tag Manager</h5>
    <p>
        Google Tag Manager ist eine Lösung, mit der wir sog. Website-Tags über eine Oberfläche verwalten können (und so
        z.B. Google Analytics sowie andere Google-Marketing-Dienste in unser Onlineangebot einbinden). Der Tag Manager
        selbst (welches die Tags implementiert) verarbeitet keine personenbezogenen Daten der Nutzer. Im Hinblick auf
        die Verarbeitung der personenbezogenen Daten der Nutzer wird auf die folgenden Angaben zu den Google-Diensten
        verwiesen. Nutzungsrichtlinien: <a href="https://www.google.com/intl/de/tagmanager/use-policy.html">https://www.google.com/intl/de/tagmanager/use-policy.html</a>.
    </p>

    <h5>Google Analytics</h5>
    <ol>
        <li>Wir haben das Webseitenanalyse-Tool „Google Analytics“ (Google Ireland Limited, Registernr.: 368047,
            Gordon House, Barrow Street, Dublin 4, Irland) auf unserer Website integriert.</li>
        <li>Beim Besuch unserer Website setzt Google einen Cookie auf Ihren Computer, um die Benutzung unserer
            Website durch Sie analysieren zu können. Die gewonnenen Daten werden in die USA übertragen und dort
            gespeichert. Falls personenbezogen Daten in die USA übertragen werden sollten, bietet die Zertifizierung
            Googles gemäß Privacy-Shield-Abkommen (<a href="https://www.privacyshield.gov/EU-US-Framework"
            target="_blank" rel="nofollow noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>) die Garantie dafür, dass
            das europäische Datenschutzrecht eingehalten wird.
        </li>
        <li>Wir haben die IP-Anonymisierung „anonymizeIP“ aktiviert, wodurch die IP-Adressen nur gekürzt
            weiterverarbeitet werden. Auf dieser Webseite wird Ihre IP-Adresse von Google daher innerhalb von
            Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen
            Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in
            den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Webseite wird Google diese
            Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten
            zusammenzustellen und um weitere, mit der Websitenutzung und der Internetnutzung verbundene,
            Dienstleistungen gegenüber dem Verantwortlichen zu erbringen. Wir haben darüber hinaus die
            geräteübergreifende Analyse von Website-Besuchern aktiviert, die über eine sog. User-ID durchgeführt wird.
            Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten
            von Google zusammengeführt. Die Nutzung von Google Analytics dient dem Zweck der Analyse, Optimierung und
            Verbesserung unserer Website.</li>
        <li>Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes berechtigtes Interesse an der
            Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.</li>
        <li>Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs verknüpften
            Daten werden nach 14 Monaten automatisch gelöscht. Die Löschung von Daten, deren Aufbewahrungsdauer erreicht
            ist, erfolgt automatisch einmal im Monat.</li>
        <li>Weitere Informationen zu Datennutzung bei Google Analytics finden Sie hier: <a
            href="https://www.google.com/analytics/terms/de.html" rel="nofollow noopener noreferrer" target="_blank">https://www.google.com/analytics/terms/de.html</a>
            (Nutzungsbedingungen von Analytics), <a href="https://support.google.com/analytics/answer/6004245?hl=de"
            rel="nofollow noopener noreferrer" target="_blank">https://support.google.com/analytics/answer/6004245?hl=de</a> (Hinweise zum
            Datenschutz bei Analytics) und Googles Datenschutzerklärung <a href="https://policies.google.com/privacy"
            rel="nofollow noopener noreferrer" target="_blank">https://policies.google.com/privacy</a>.
        </li>
        <li>Widerspruch und „Opt-Out“: Das Speichern von Cookies auf Ihrer Festplatte können Sie allgemein
            verhindern, indem Sie in Ihren Browser-Einstellungen „keine Cookies akzeptieren“ wählen. Dies kann aber eine
            Funktionseinschränkung unserer Angebote zur Folge haben. Sie können darüber hinaus die Erfassung der, durch
            das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen, Daten an Google sowie die Verarbeitung
            dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin
            herunterladen und installieren: <a href="http://tools.google.com/dlpage/gaoptout?hl=de" rel="nofollow noopener noreferrer"
            target="_blank">http://tools.google.com/dlpage/gaoptout?hl=de</a>
        </li>
        <li>Als Alternative zum obigen Browser-Plugin können Sie die Erfassung durch Google Analytics unterbinden,
            indem Sie <a id="ga-opt-out" href="#!">Google Analytics deaktivieren</a> klicken. Durch den Klick wird ein
            „Opt-out“-Cookie gesetzt, das die Erfassung Ihrer Daten beim Besuch dieser Webseite zukünftig verhindert.
            Sollten Sie keine Bestätigungsmeldung für das Setzen des Cookie bekommen, nutzen Sie einen Ad-Blocker, der
            das Setzen des Cookie unterbindet. Vermutlich unterdrückt der Ad-Blocker dann auch bereits die Datenerhebung
            durch Google Analytics auf dieser Website. Dieses Cookie gilt nur für unsere Webseite und Ihren aktuellen
            Browser und hat nur solange Bestand bis Sie Ihre Cookies löschen. In dem Falle müssten Sie das Cookie erneut
            setzen.
        </li>
        <li>Die geräteübergreifende Nutzeranalyse können Sie in Ihrem Google-Account unter „Meine Daten -
            persönliche Daten“ deaktivieren.</li>
    </ol>

    <h4>Einbindung von Diensten und Inhalten Dritter</h4>
    <h5>Allgemeine Hinweise</h5>
    <p>Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer berechtigten Interessen (d.h. Interesse an
        der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f.
        DSGVO) Inhalts- oder Serviceangebote von Drittanbietern ein, um deren Inhalte und Services, wie z.B. Videos oder
        Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als “Inhalte”).</p>
    <p>Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der Nutzer wahrnehmen, da sie
        ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die
        Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige
        Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner so
        genannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) für statistische oder
        Marketingzwecke verwenden. Durch die "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den Seiten
        dieser Website ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf dem Gerät der
        Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und Betriebssystem, verweisende
        Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten, als auch mit solchen
        Informationen aus anderen Quellen verbunden werden.</p>

    <h5>Google Fonts</h5>
    <p>Wir binden die Schriftarten ("Google Fonts") des Anbieters Google Ireland Limited, Gordon House, Barrow
        Street, Dublin 4, Irland, ein. Nach Angaben von Google werden die Daten der Nutzer allein zu Zwecken der
        Darstellung der Schriftarten im Browser der Nutzer verwendet. Die Einbindung erfolgt auf Grundlage unserer
        berechtigten Interessen an einer technisch sicheren, wartungsfreien und effizienten Nutzung von Schriftarten,
        deren einheitlicher Darstellung sowie Berücksichtigung möglicher lizenzrechtlicher Restriktionen für deren
        Einbindung. Datenschutzerklärung: https://www.google.com/policies/privacy/.</p>

    <h4>Rechte der betroffenen Person</h4>
    <ol>
        <li><strong>Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten</strong>: Soweit die Verarbeitung
            auf Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a), Art. 7 DS-GVO beruht, haben Sie das Recht, die
            Einwilligung jederzeit zu widerrufen. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
            erfolgten Verarbeitung wird dadurch nicht berührt. Soweit wir die Verarbeitung Ihrer personenbezogenen Daten
            auf die Interessenabwägung gemäß Art. 6 Abs. 1 S. 1 lit. f) DS-GVO stützen, können Sie Widerspruch gegen die
            Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung eines
            Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der nachfolgenden Beschreibung der Funktionen
            dargestellt wird. Bei Ausübung eines solchen Widerspruchs bitten wir um Darlegung der Gründe, weshalb wir
            Ihre personenbezogenen Daten nicht wie von uns durchgeführt verarbeiten sollten. Im Falle Ihres begründeten
            Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder
            Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen. Sie
            können der Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung und Datenanalyse jederzeit
            widersprechen. Das Widerspruchsrecht können Sie kostenfrei ausüben. Über Ihren Werbewiderspruch können Sie
            uns unter folgenden Kontaktdaten informieren: Olaf Kortlüke Im Brachmoos 12 88149 Nonnenhorn E-Mail-Adresse:
            olaf@apocha.de</li>
        <li><strong>Recht auf Auskunft</strong> Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen,
            ob Sie betreffende personenbezogene Daten verarbeitet werden. Sofern dies der Fall ist, haben Sie ein Recht
            auf Auskunft über Ihre bei uns gespeicherten persönlichen Daten nach Art. 15 DS-GVO. Dies beinhaltet
            insbesondere die Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die
            Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante
            Speicherdauer, die Herkunft ihrer Daten, sofern diese nicht direkt bei Ihnen erhoben wurden.</li>
        <li><strong>Recht auf Berichtigung</strong> Sie haben ein Recht auf Berichtigung unrichtiger oder auf
            Vervollständigung richtiger Daten nach Art. 16 DS-GVO.</li>
        <li><strong>Recht auf Löschung</strong> Sie haben ein Recht auf Löschung Ihrer bei uns gespeicherten Daten
            nach Art. 17 DS-GVO, es sei denn gesetzliche oder vertraglichen Aufbewahrungsfristen oder andere gesetzliche
            Pflichten bzw. Rechte zur weiteren Speicherung stehen dieser entgegen.</li>
        <li><strong>Recht auf Einschränkung</strong> Sie haben das Recht, eine Einschränkung bei der Verarbeitung
            Ihrer personenbezogenen Daten zu verlangen, wenn eine der Voraussetzungen in Art. 18 Abs. 1 lit. a) bis d)
            DS-GVO erfüllt ist: • Wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer
            bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu
            überprüfen;• die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und
            stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen; • der Verantwortliche die
            personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder • wenn Sie Widerspruch gegen
            die Verarbeitung gemäß Art. 21 Abs. 1 DS-GVO eingelegt haben und noch nicht feststeht, ob die berechtigten
            Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.</li>
        <li><strong>Recht auf Datenübertragbarkeit</strong> Sie haben ein Recht auf Datenübertragbarkeit nach Art.
            20 DS-GVO, was bedeutet, dass Sie die von Ihnen zur Verfügung gestellten und bei uns über Sie gespeicherten
            personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format erhalten können oder
            die Übermittlung an einen anderen Verantwortlichen verlangen können.</li>
        <li><strong>Recht auf Beschwerde</strong> Sie haben ein Recht auf Beschwerde bei einer Aufsichtsbehörde. In
            der Regel können Sie sich hierfür an die Aufsichtsbehörde insbesondere in dem Mitgliedstaat ihres
            Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes wenden.</li>
    </ol>

    <h4>Datensicherheit</h4>
    <p>Um alle personenbezogen Daten, die an uns übermittelt werden, zu schützen und um sicherzustellen, dass die
        Datenschutzvorschriften von uns, aber auch unseren externen Dienstleistern eingehalten werden, haben wir
        geeignete technische und organisatorische Sicherheitsmaßnahmen getroffen. Deshalb werden unter anderem alle
        Daten zwischen Ihrem Browser und unserem Server über eine sichere SSL-Verbindung verschlüsselt übertragen.</p>
    <p>
        <strong>Stand: 28.12.2019</strong>
    </p>
    <p>
        Angepasst auf Basis der <a href="https://www.juraforum.de/impressum-generator/" rel="nofollow noopener noreferrer"
        target="_blank">Muster-Datenschutzerklärung von JuraForum.de</a> und des <a href="https://datenschutz-generator.de"
        rel="nofollow noopener noreferrer" target="_blank">datenschutz-generator.de von RA Dr. Thomas Schwenke</a>
    </p>
</DataProtectionContainer>
    <Footer />
  </Layout>
)

export default DataProtectionPage

const DataProtectionContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 80px 18px 40px;
`
